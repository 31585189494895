<section class="full-content-layout">
  <div class="container h-100">
    <p class="pt-5 text-uppercase fs-4 fw-bold">
      <a routerLink="/dashboard" class="logo">
        <img src="../../../../assets/imgs/logo/fast money trade logo 1.png" alt="">
      </a>
    </p>

    <div class="row align-items-center justify-content-center">
      <div class="col-md-6">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</section>

@if (toaster.visible) {
<!-- toaster -->
<div class="custom-alert d-flex gap-4 toaster" [ngClass]="toaster.statue">
  <div class="icon">
    @if (toaster.statue == 'success') {
    <i class="fa-solid fa-circle-check"></i>
    } @else if (toaster.statue == 'error') {
    <i class="fa-solid fa-circle-xmark"></i>
    } @else {
    <i class="fa-solid fa-circle-exclamation"></i>
    }
  </div>
  <div class="text flex-grow-1">
    <div class="head d-flex align-items-start justify-content-between">
      <h5>{{toaster.title}}</h5>
      <i class="fa-solid fa-xmark curser-pointer" (click)="toaster.visible = false"></i>
    </div>
    <p>{{toaster.message}}</p>
  </div>
</div>
}