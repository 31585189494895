import { Component, OnInit, inject } from '@angular/core';
import { RouterOutlet, RouterLink, RouterModule } from '@angular/router';
import { Toaster, ToasterService } from '../../services/toaster.service';
import { CommonModule } from '@angular/common';
import { FullLayoutToasterService } from '../../services/full-layout-toaster.service';

@Component({
  selector: 'app-full-content-layout',
  templateUrl: './full-content-layout.component.html',
  styleUrl: './full-content-layout.component.scss',
  standalone: true,
  imports: [RouterOutlet, RouterModule, CommonModule],
})
export class FullContentLayoutComponent implements OnInit {
  public toaster: Toaster = { visible: false };

  private _FullLayoutToasterService = inject(FullLayoutToasterService);
  ngOnInit(): void {
    let toastTimer: any;
    this._FullLayoutToasterService.showToaster.subscribe((res) => {
      if (res.visible) {
        this.toaster = res;
        toastTimer = setTimeout(() => {
          this.toaster.visible = false;
        }, 4000);
      } else {
        clearTimeout(toastTimer);
      }
    });
  }
}
