import { map } from 'rxjs/operators';
import { inject } from "@angular/core";
import { ExchangesService } from "../../../../modules/orders/services/exchanges.service";
import { SideMenu, SideMenuService } from "../services/side-menu.service";
import { WithdrawService } from "../../../../modules/orders/services/withdraw.service";
import { TopUpService } from "../../../../modules/orders/services/top-up.service";
import { TicketService } from "../../../../modules/ticket/services/ticket.service";
import { tap } from "rxjs";
import { event, noop } from "jquery";
import { ContactUsService } from "../../../../modules/contact-us/services/contact-us.service";
import { RequestGatewayService } from "../../../../modules/new-gateway/services/request-gateway.service";
import { TwoFARequestsService } from "../../../../modules/reset-2fa-requests/services/two-farequests.service";

export class LivePoint {
  public _ExchangesService = inject(ExchangesService);
  public _WithdrawService = inject(WithdrawService);
  public _TopUpService = inject(TopUpService);
  public _SideMenuService = inject(SideMenuService);
  public _TicketService = inject(TicketService);
  public _ContactUsService = inject(ContactUsService);
  public _RequestGatewayService = inject(RequestGatewayService);
  public _TwoFARequestsService = inject(TwoFARequestsService);
  public items = this._SideMenuService.items;
  public itemsFooter = this._SideMenuService.itemsFooter;

  // !  Vars For If Status Counts
  public orders = false;
  public topUp = false;
  public withdraw = false;
  public tickets = false;
  public contacts = false;
  public newGateway = false;
  public reset2FA = false;
  // ! ==============================

  constructor() {
    this.getCountTicketsByStatus()
    // ! For Orders
    this.getExchangesForGetLengthsOfStatus();
    this.getTopUpsForGetLengthsOfStatus();
    this.getWithdrawsForGetLengthsOfStatus();
    // ! ==================================

    // ! For Tickets

    this.getCountTicketsByStatus();
    this.getContactsCounts();
    this.getNewGatewayRequestCounts();
    this.getReset2FARequestCounts();
    // ! ==================================
  }

  // ! For Orders Dropdown

  // ? get length of status os exchanges
  getExchangesForGetLengthsOfStatus() {
    this._ExchangesService.status.subscribe({
      next: (res) => {
        this.items.map((ele) => {
          if (ele.class1 == 'orders') {
            ele.items?.map((item) => {
              if (item.class1 == 'all-exchanges') {
                this.livePointForOrders();
                item.eventValue = res?.processing;
              }
            });
          }
        });
      },
    });
  }

  // ? get length of status os withdraws
  getWithdrawsForGetLengthsOfStatus() {
    this._WithdrawService.status.subscribe((res) => {
      this.items.map((ele) => {
        if (ele.class1 == 'orders') {
          ele.items?.map((item) => {
            if (item.class1 == 'withdraw-list') {
              this.livePointForOrders();
              item.eventValue = res?.processing;
            }
          });
        }
      });
    });
  }

  // ? get length of status os top ups
  getTopUpsForGetLengthsOfStatus() {
    this._TopUpService.status.subscribe((res) => {
      this.items.map((ele) => {
        if (ele.class1 == 'orders') {
          ele.items?.map((item) => {
            if (item.class1 == 'tob-up-list') {
              this.livePointForOrders();
              item.eventValue = res?.processing;
            }
          });
        }
      });
    });
  }

  // ? set counts
  livePointForOrders() {
    let element!: SideMenu;
    let count1 = 0;
    let count2 = 0;
    let count3 = 0;
    this._ExchangesService.status.subscribe((res) => {
      this.items.map((ele) => {
        if (ele.class1 == 'orders') {
          element = ele;
          if (res?.processing) {
            count1 = 1;
          } else {
            count1 = 0;
          }
        }
      });
    });
    this._WithdrawService.status.subscribe((res) => {
      this.items.map((ele) => {
        if (ele.class1 == 'orders') {
          if (res?.processing) {
            count2 = 1;
          } else {
            count2 = 0;
          }
        }
      });
    });
    this._TopUpService.status.subscribe((res) => {
      this.items.map((ele) => {
        if (ele.class1 == 'orders') {
          if (res?.processing) {
            count2 = 1;
          } else {
            count3 = 0;
          }
        }
      });
    });
    if (count1 || count2 || count3) {
      element.event = true;
    } else {
      element.event = false;
    }
  }

  // ! ============================================================

  // ! For Tickets Dropdown

  // ? tickets
  getCountTicketsByStatus() {
    this._TicketService.getCountTicketsByStatus().pipe(tap(
      status => {
        console.log('status: ', status);
        this._TicketService.statusCounts.next(status)
        this._TicketService.statusCounts.subscribe(res => {
          this.items.map(ele => {
            if (ele.class1 == 'ticket' && res.open) {
              console.log('ele.class1: ', ele.class1);
              this.tickets = true;
              ele.items?.map(item => {
                if (item.class1 == 'ticket-list') {
                  item.eventValue = res.open;
                  this.runningLivePointForTickets(ele)
                }
              })
            } else {
              this.tickets = false;
            }

          })
        })
      }
    )).subscribe(noop, err => console.log(err))
  }

  // ? contact us
  getContactsCounts() {
    this._ContactUsService.getStatusCounts().pipe(tap(
      count => {
        this._ContactUsService.statusCounts.next(count);
        this._ContactUsService.statusCounts.subscribe(res => {
          this.items.map(ele => {
            if (ele.class1 == 'ticket' && res.removedFalse) {
              this.contacts = true;
              ele.items?.map(item => {
                if (item.class1 == 'contact-us-list') {
                  item.eventValue = res.removedFalse;
                  this.runningLivePointForTickets(ele)
                }
              })
            } else {
              this.contacts = false;
            }
          })
        })
      }
    )).subscribe(noop, err => console.log(err))
  }

  // ? request new gateway
  getNewGatewayRequestCounts() {
    this._RequestGatewayService.getNewGatewayRequestCounts().pipe(tap(
      count => {
        this._RequestGatewayService.statusCounts.next(count);
        this._RequestGatewayService.statusCounts.subscribe(res => {
          this.items.map(ele => {
            if (ele.class1 == 'ticket'  && res.removedFalse) {
              this.newGateway = true;
              ele.items?.map(item => {
                if (item.class1 == 'requests-new-gateways') {
                  item.eventValue = count.removedFalse;
                  this.runningLivePointForTickets(ele)
                }
              })
            } else {
              this.newGateway = false;
            }
          })
        })
      }
    )).subscribe(noop, err => console.log(err))
  }

  // ? reset 2FA
  getReset2FARequestCounts() {
    this._TwoFARequestsService.getReset2FARequestCounts().pipe(tap(
      count => {
        this._TwoFARequestsService.statusCounts.next(count);
        this._TwoFARequestsService.statusCounts.subscribe(res => {
          this.items.map(ele => {
            if (ele.class1 == 'ticket' && res.PENDING) {
              this.reset2FA = true;
              ele.items?.map(item => {
                if (item.class1 == 'reset-2fa-requests') {
                  item.eventValue = res.PENDING;
                  this.runningLivePointForTickets(ele)
                }
              })
            } else {
              this.reset2FA = false;
            }
          })
        })
      }
    )).subscribe(noop, err => console.log(err))
  }

  runningLivePointForTickets(item: SideMenu) {
    this.items.map(ele => {
      if (ele.class1 == 'ticket') {
        ele.items?.map( item => {
          if (item.eventValue) {
            ele.event = true;
          }
        })
      }
    })
  }

  // ! ============================================================



}

export interface StatusCounts {
  removedTrue: number;
  removedFalse: number;
}