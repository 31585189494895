import { HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { ToasterService } from '../../layouts/services/toaster.service';

export const apiInterceptor: HttpInterceptorFn = (req, next) => {
  // for toaster
  let _ToasterService = inject(ToasterService);
  if ( req.url.includes('block-admin') || req.url.includes('freeze-admin') || req.url.includes('block-user') || req.url.includes('freeze-user')) {
    _ToasterService.showToaster.next({ visible: false });
  }

  // Authorization of requests
  let userToken = sessionStorage.getItem('adminUserToken');

  let myReq: HttpRequest<unknown>;
  if ( req.url.includes('https://money-exchange-app.s3.eu-central-1.amazonaws.com') ) {
    return next(req);
  }
  if (!req.url.includes('admin-validate-2fa')) {
    myReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${userToken}`),
    });
    return next(myReq);
  } else {
    return next(req);
  }
};
