import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { routes } from './app.routes';
import { HttpClientModule, provideHttpClient, withInterceptors } from '@angular/common/http';

// interceptors
import { errorInterceptor } from './core/interceptor/error.interceptor';
import { apiInterceptor } from './core/interceptor/api.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    importProvidersFrom([BrowserAnimationsModule, HttpClientModule]),
    provideHttpClient(withInterceptors([errorInterceptor, apiInterceptor])),
  ],
};
