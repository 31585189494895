<section *ngIf="items && itemsFooter" class="side-menu" [ngClass]="sideMenuOpened ? 'side-menu-default-width' : 'side-menu-collapsed'">
  <div class="text-center logo" [ngClass]="sideMenuOpened ? 'side-menu-default-width' : ''">
    <img *ngIf="sideMenuOpened" src="assets/imgs/logo/fast money trade logo 1.png" alt="">
    <img *ngIf="!sideMenuOpened && (w1150 || w991 || w768 || w480)" src="assets/imgs/logo/fast money trade logo 2.png"
      class="mobile-logo" alt="">

  </div>
  <div class="items">
    <div class="row flex-column align-items-center justify-content-between g-0">
      <!-- side menu -->

      <div class="col-12 px-0 flex-grow-1 side">
        <ul class="list-unstyled">
          <li class="nav-item curser-pointer position-relative" *ngFor="let item of items">
            <!-- the click events for toggle dropdown and ngClass for activated routs style -->
            <a class="side-menu-link d-flex justify-content-between align-items-center"
              (click)="item.routerLink ? _Router.navigate([item.routerLink]) : ''" (click)="[ item.dropdown && !item.expand ? item.expand = true : item.expand = false, collapseDropdowns(item),
                !sideMenuOpened && item.items ? openSideMenu(true) : '']"
              [ngClass]="[item.routeActive || item.expand ? 'side-menu-link-active' : '']">
              <!-- default text -->
              <div class="side-item" [ngClass]="!sideMenuOpened ? 'text-center' : ''">
                <i [ngClass]=" ['text-main', item.icon, sideMenuOpened ? 'me-2' : 'm-0' ]"></i>
                <span *ngIf="sideMenuOpened">{{ item.label }}</span>
              </div>
              <!-- live point -->
              @if (item.event) {
              <div [ngClass]="sideMenuOpened ? 'flex-grow-1 live-point me-2' : 'position-absolute live-point-mobile'">
                <div [ngClass]="sideMenuOpened ? 'shaking position-relative ms-auto' : 'shaking-mobile'"></div>
              </div>
              }
              <!-- if it dropdown appear chevron down -->
              <i *ngIf="item.items && sideMenuOpened " class="pi pi-chevron-down text-main text-end chevron"></i>
            </a>

            <!-- add class to sub menu for set height to appear sub menu -->
            <ul class="list-unstyled sub-menu overflow-hidden"
              [ngClass]="[ item.expand && item.items ? 'expand-dropdown-' + item.lengthOfItems : '', ]"
              *ngIf="item.items">
              <li class="nav-item" *ngFor="let item of item.items">
                <!-- this classes identical with route of all components of module -->
                <a class="sub-menu-link d-block text-main d-flex justify-content-between align-items-center"
                  [ngClass]=" item.class1 == innerCompoRoute || item.class2 == innerCompoRoute || item.class3 == innerCompoRoute || item.class4 == innerCompoRoute ? 'sub-menu-link-active' : '' "
                  (click)="item.routerLink ? _Router.navigate([item.routerLink]) : ''">
                  @if (item.eventValue) {
                  <span>{{ item.label }}</span> <span class="event-value m-2">{{item.eventValue}}</span>
                  } @else { {{ item.label }} }
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <!-- footer pages -->
      <div class="col-12 px-0 footer">
        <ul class="list-unstyled">
          <li class="nav-item curser-pointer position-relative"  *ngFor="let item of itemsFooter"  pTooltip="Enter" tooltipPosition="right" >
            <a class="side-menu-link d-flex"
              [ngClass]=" [ item.expand || item.routeActive ? 'side-menu-link-active' : '', ]"
              (click)="item.routerLink ? _Router.navigate([item.routerLink]) : ''" (click)="item.logout ? logout() : ''"
              (click)="[ item.dropdown && !item.expand ? item.expand = true : item.expand = false, collapseDropdownsFooter(item), !sideMenuOpened && item.items ? openSideMenu(true) : '']">

              <!-- default text -->
              <div class="side-item" [ngClass]="!sideMenuOpened ? 'text-center' : ''">
                <i [ngClass]="[' text-main ' , item.icon , sideMenuOpened ? ' me-2 ' : ' m-0 ']"></i><span
                  *ngIf="sideMenuOpened">{{ item.label }}</span>
              </div>
              <i *ngIf="item.items && sideMenuOpened"
                class="pi pi-chevron-down text-main flex-grow-1 text-end chevron"></i>
            </a>

            <ul class="list-unstyled sub-menu overflow-hidden"
              [ngClass]="[ item.expand ? 'expand-dropdown-'+ item.lengthOfItems : '' ]" *ngIf="item.items">
              <li class="nav-item" *ngFor="let item of item.items">
                <a class="sub-menu-link d-block text-main" [ngClass]=" item.class1 == innerCompoRoute && item.routeActive || item.class2 == innerCompoRoute && item.routeActive ? 'sub-menu-link-active' : ''
                  " (click)="item.routerLink ? _Router.navigate([item.routerLink]) : ''">{{ item.label }}</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>

    <div class="layer-side"></div>

    <div class="open-side-menu" (click)="[sideMenuOpened = !sideMenuOpened, openSideMenu(sideMenuOpened)]"
      *ngIf="w1150 || w991 || w768 || w480"><i [class]="sideMenuOpened ? 'pi pi-chevron-left' : 'pi pi-chevron-right'"></i>
    </div>
  </div>
</section>