import { Component, OnInit, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { AuthService } from '../../../modules/auth/services/auth.service';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { IUserInfo } from '../../../modules/users/interfaces/user-info';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [InputTextModule, HttpClientModule, FormsModule, CommonModule, RouterModule],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent implements OnInit {
  searchTerm!: string;

  public userInfo!: IUserInfo | null;
  private _AuthService = inject(AuthService);
  public _Router = inject(Router);

  ngOnInit(): void {
    this.loadUserData();
  }

  loadUserDatainfo() {
    this._AuthService.getUserInfo().subscribe({
      next: (res) => {
        // console.log("res get user info navbar", res);
        this._AuthService.userInfo.next(res);
      },
      error: (error) => {
        console.log("settigns error load data", error);

      }
    });
  }

  loadUserData() {
    this._AuthService.userInfo.subscribe(res => {
      if (res == null) {
        this.loadUserDatainfo();
      }
      this.userInfo = res;
    });
  }

}
