import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, throwError } from 'rxjs';
import { AuthService } from '../../modules/auth/services/auth.service';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {

  let _Router = inject(Router);
  let _AuthService = inject(AuthService);

  // console.log("request intercept ", req);
  return next(req).pipe(

    catchError((error) => {
      // if Unauthorized or 403
      if ([401, 403].includes(error.error.statusCode)) {
        _AuthService.logout();
      }

      console.log("interceptor error", error.error);
      console.log("interceptor error message", error.error.message);
      console.log("interceptor error adminUserToken", sessionStorage.getItem('adminUserToken') ? true : false, "error status", error.status);

      // if token refresh and token are gone - expired
      if ([410].includes(error.error.statusCode)) {
        _AuthService.logout();
      }

      return throwError(() => error);
    })
  );
};
