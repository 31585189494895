import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
  inject,
} from '@angular/core';
import { NavigationStart, Router, RouterOutlet } from '@angular/router';
import { SideMenuComponent } from '../../components/side-menu/side-menu.component';
import { NavbarComponent } from '../../components/navbar/navbar.component';
import { CommonModule } from '@angular/common';
import { Toaster, ToasterService } from '../../services/toaster.service';

@Component({
  selector: 'app-nav-layout',
  standalone: true,
  imports: [RouterOutlet, SideMenuComponent, NavbarComponent, CommonModule],
  templateUrl: './nav-layout.component.html',
  styleUrl: './nav-layout.component.scss',
})
export class NavLayoutComponent implements OnInit {
  // for auto scroll
  @ViewChild('window') window!: ElementRef;
  public scrollInterval: any;

  public toaster: Toaster = { visible: false };
  public _ToasterService = inject(ToasterService);
  public _Router = inject(Router);

  ngOnInit(): void {
    this.toast();
    this.watchRoute();
  }
  watchRoute() {
    this._Router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        document.querySelector('.window')!?.scrollTo(0, 0);
      }
    });
  }

  toast() {
    let toastTimer: any;
    this._ToasterService.showToaster.subscribe((res) => {
      if (res.visible) {
        this.toaster = res;
        toastTimer = setTimeout(() => {
          this.toaster.visible = false;
        }, 4000);
      } else {
        clearTimeout(toastTimer);
      }
    });
  }
}
