import { Routes } from '@angular/router';
import { FullContentLayoutComponent } from './layouts/pages/full-content-layout/full-content-layout.component';
import { NavLayoutComponent } from './layouts/pages/nav-layout/nav-layout.component';
import { authGuard } from './modules/auth/guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    canActivate: [authGuard],
    component: NavLayoutComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./modules/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./modules/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'wallets',
        loadChildren: () =>
          import('./modules/wallets/wallets.module').then(
            (m) => m.WalletsModule
          ),
      },
      {
        path: 'gateways',
        loadChildren: () =>
          import('./modules/gateway/gateway.module').then(
            (m) => m.GatewayModule
          ),
      },
      {
        path: 'payment-methods',
        loadChildren: () =>
          import('./modules/payment-methods/payment-methods.module').then(
            (m) => m.PaymentMethodsModule
          ),
      },
      {
        path: 'currencies',
        loadChildren: () =>
          import('./modules/currencies/currencies.module').then(
            (m) => m.CurrenciesModule
          ),
      },
      {
        path: 'email',
        loadChildren: () =>
          import('./modules/email/email.module').then((m) => m.EmailModule),
      },
      {
        path: 'directions',
        loadChildren: () =>
          import('./modules/exchange/exchange.module').then(
            (m) => m.ExchangeModule
          ),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./modules/settings/settings.module').then(
            (m) => m.SettingsModule
          ),
      },
      {
        path: 'orders',
        loadChildren: () =>
          import('./modules/orders/orders.module').then((m) => m.OrdersModule),
      },
      {
        path: 'ticket',
        loadChildren: () =>
          import('./modules/ticket/ticket.module').then((m) => m.TicketModule),
      },
      {
        path: 'new-gateway',
        loadChildren: () =>
          import('./modules/new-gateway/new-gateway.module').then(
            (m) => m.NewGatewayModule
          ),
      },
      {
        path: 'footer-pages',
        loadChildren: () =>
          import('./modules/footer-pages/footer-pages.module').then(
            (m) => m.FooterPagesModule
          ),
      },
      {
        path: 'contact-us',
        loadChildren: () =>
          import('./modules/contact-us/contact-us.module').then(
            (m) => m.ContactUsModule
          ),
      },
      {
        path: 'charts',
        loadChildren: () =>
          import('./modules/charts/charts.module').then((m) => m.ChartsModule),
      },
      {
        path: 'reset-2fa',
        loadChildren: () =>
          import('./modules/reset-2fa-requests/reset-2fa-requests.module').then(
            (m) => m.Reset2faRequestsModule
          ),
      },
    ],
  },
  {
    path: 'auth',
    component: FullContentLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import(`./modules/auth/auth.module`).then((m) => m.AuthModule),
      },
    ],
  },
];
